import router from '../router'
const whiteList = ['/login']
router.beforeEach((to, from, next) => {
    let userInfo = sessionStorage.pvEnergyUserInfo
        ? JSON.parse(sessionStorage.pvEnergyUserInfo)
        : {}
    if(userInfo.token) {
        next()
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            // in the free login whitelist, go directly
            next()
        } else {
            // other pages that do not have permission to access are redirected to the login page.
            next(`/login`)
        }
    }

})
