import { handleGetConnect } from '@/api/index'
export default {
  data () {
    return {
      refreshSystem: false,
      interval: null
    }
  },

  methods: {
    async handleGetConnect () {
      let result = await handleGetConnect()

      let data = result || []
      for (let i = 0; i < data.length; i++) {
        if (data[i].code == 'SYS_VERSION') {
          if (
            sessionStorage['pv-energy-system-version'] &&
            data[i].attr1 != sessionStorage['pv-energy-system-version']
          ) {
            this.refreshSystem = true
          }
          sessionStorage.setItem('pv-energy-system-version', data[i].attr1)
        }
      }
    },
    handleExamineUserInfo () {
      this.refreshSystem = false
    }
  },
  mounted () {
    this.handleGetConnect()
    this.interval && clearInterval(this.interval)
    this.interval = setInterval(this.handleGetConnect, 60000)
  },
  destroyed () {
    this.interval && clearInterval(this.interval)
  },
  created () {}
}
