import request from '@/utils/request'

/**
 * @description: 忘记密码
 * @param {*}
 * @return {*}
 */
export function getCode () {
    return request({
        url: '/system/core/captcha/code',
        method: 'get'
    })
}

/**
 * @description: 登录
 * @param {*}
 * @return {*}
 */

export function login (data) {
    return request({
        url: '/system/core/user/login/pwd',
        method: 'post',
        data
    })
}

/**
 * @description: 刷新token
 * @param {*}
 * @return {*}
 */

export function tokenRefresh (data) {
  return request({
    url: '/system/core/user/token/refresh',
    method: 'post',
    data
  })
}

/**
 * @description:
 * @param {*}
 * @return {*}
 */

export function handleGetConnect() {
    return request({
        url:'/system/core/base/constant/RSPS',
        method: 'get'
    })
}

