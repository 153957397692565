/**在Vue 3中，全局指令的注册需要使用app.directive方法，
 * 而不再需要在Vue上直接调用。在install方法中，
 * app参数代表Vue应用的实例，通过调用app.directive来注册全局指令。
 * 另外，由于Vue 3的模块化系统改变，导入Vue的方式也有所不同，
 * 不再需要import Vue from 'vue'，
 * 而是直接传递Vue应用的实例作为参数。 */
import Loading from './loading'

export default {
  install(app) {
    app.directive('myLoading', Loading)
  }
}
