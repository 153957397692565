import { createApp, nextTick } from 'vue';
import Loading from '@/components/loading';

// 创建Loading组件实例
const Mask = createApp(Loading);
const maskInstance = Mask.mount(document.createElement('div'));

// 更新是否显示
const toggleLoading = (el, binding) => {
  if (binding.value) {
    nextTick(() => {
      // 控制loading组件显示
      el.instance.visible = true;
      el.style.position = 'relative';
      // 插入到目标元素
      insertDom(el, el);
    });
  } else {
    el.instance.visible = false;
    el.style.position = 'static';
    el.mask && el.mask.parentNode && el.mask.parentNode.removeChild(el.mask);
  }
};

// 插入到目标元素
const insertDom = (parent, el) => {
  parent.appendChild(el.mask);
};

export default {
  // 第一次绑定到元素时调用
  mounted(el, binding) {
    // 用一个变量接住mask实例
    el.instance = maskInstance;
    el.mask = maskInstance.$el;
    el.maskStyle = {};
    binding.value && toggleLoading(el, binding);
  },
  // 所在组件的 VNode 更新时调用--比较更新前后的值
  updated(el, binding) {
    if (binding.oldValue !== binding.value) {
      toggleLoading(el, binding);
    }
  },
  // 指令与元素解绑时调用
  unmounted(el) {
    el.instance = null;
    el.mask = null;
    el.maskStyle = null;
  },
};