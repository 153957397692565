<template>
  <transition name="fade">
    <section>
      <div class="loading">
        <img class="icon-animation" src="@/assets/loading.png" />
        <p class="desc">数据加载中...</p>
      </div>
    </section>
  </transition>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: '正在载入...'
    }
  },
  data () {
    return {}
  }
}
</script>
<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
section {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: transparent;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  .loading {
    width: 100%;
    text-align: center;
    /* flex-direction: column; */
  }
  .icon-animation {
    width: 32px;
    height: 32px;
    animation: iconRotate 1s linear infinite;
  }
  
  .desc {
    display: none;
    font-size: 24px;
    color: #fff;
  }
}



</style>
