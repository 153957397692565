const getters = {
  token: state => state.index.token,
  expireAt: state => state.index.expireAt,
  projectId: state => state.index.projectId,
  refreshToken: state => state.index.refreshToken,
  refreshExpireAt: state => state.index.refreshExpireAt,
  userId: state => state.index.userId,
  userInfo: state => state.index.userInfo
}
export default getters
