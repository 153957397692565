import axios from 'axios'
import {ElMessage, ElMessageBox} from 'element-plus'
import {tokenRefresh} from '@/api/index'
import router from '@/router'
import store from '@/store'
// 创建axios实例
const request = axios.create({
    baseURL: process.env.VUE_APP_DEV_BASE_API,
    timeout: 30000,
    withCredentials: true
})
window.isRereshing = false //防止同一时间段屡次刷新
window.isLogout = false
request.interceptors.request.use(
    config => {

        config.headers.AuthSysCode = 'RSPS'
        if (store.getters.token) {
            let userInfo = sessionStorage.getItem('pvEnergyUserInfo')
            let token = JSON.parse(userInfo).token
            config.headers.Authorization = `YWTE#${token}`
            refreshTokenInterval()
            if (!window.tokenInterval) {
                window.tokenInterval = setInterval(refreshTokenInterval, 1000)
            }
        } else {
            if (
                config.url.indexOf('system/core/captcha/code') != -1 ||
                config.url.indexOf('system/core/user/password/reset') != -1 ||
                config.url.indexOf('system/core/sms/code') != -1 ||
                config.url.indexOf('system/core/base/constant') != -1
            ) {
                return config
            }
            if (config.url.indexOf('/system/core/user/login/pwd') == -1) {
                window.tokenInterval && clearInterval(window.tokenInterval)
                sessionStorage.removeItem('pvEnergyUserInfo')
                router.push('/login')
                return
            }

        }

        return config
    },
    error => {
        return Promise.reject(error)
    }
)

// respone拦截器
request.interceptors.response.use(
    response => {
        const res = response.data
        if (res.code !== 200) {
            // 401:未登录
            if (res.code === 401) {
                if (window.isLogout) return
                window.isLogout = true
                ElMessageBox.confirm(
                    '登录过期，您可以点击取消继续留在该页面，或者重新登录',
                    '确定登出',
                    {
                        confirmButtonText: '重新登录',
                        cancelButtonText: '取消',
                        closeOnClickModal: false,
                        type: 'warning',
                        customClass: 'my-confirm-box'
                    }
                ).then(() => {
                    window.tokenInterval && clearInterval(window.tokenInterval)
                    sessionStorage.removeItem('pvEnergyUserInfo')
                    router.push('/login')
                    setTimeout(() => {
                        window.isLogout = false
                    }, 1000)
                }).catch(() => {
                    window.isLogout = false
                })
            } else if (res.code === 311 || res.code == 310 || res.code == 312 || res.code == 313) {
                return Promise.reject(res)
            } else {
                if (res.message == 'ERROR-NotAuthToken[E10001]') {
                    if (window.isLogout) return
                    window.isLogout = true
                    ElMessage({
                        message: res.message || 'Error',
                        type: 'error'
                    })

                    window.tokenInterval && clearInterval(window.tokenInterval)
                    sessionStorage.removeItem('pvEnergyUserInfo')
                    setTimeout(() => {
                        window.isLogout = false
                    }, 1000)
                    router.push('/login')

                    return
                }
                ElMessage({
                    message: res.message || 'Error',
                    type: 'error',
                    duration: 2 * 1000
                })
            }

            return Promise.reject(res.data)
        } else {
            return res.data
        }
    },
    error => {
        if (error.response && error.response.status) {
            ElMessage({
                message: error.message,
                type: 'error',
                duration: 5 * 1000
            })
            return Promise.reject(error)
        } else {
            if (error.message.includes('timeout')) {
                ElMessage({
                    message: '请求超时，请稍后再试!',
                    type: 'error',
                    duration: 1000
                })
            }
        }
    }
)

//校验token是否失效，失效时间前一个小时，调用刷新接口，将refreshToken传给后端，获取新数据与companySet保持一致
function refreshTokenInterval() {
    let userInfo = sessionStorage.getItem('pvEnergyUserInfo')
    if (userInfo) {
        let {expireAt, refreshToken} = JSON.parse(userInfo)
        let invalidTime = expireAt - 50 * 60 * 1000

        let nowTime = new Date().getTime()
        if (nowTime >= invalidTime) {
            //是否正在刷新
            if (!window.isRereshing) {
                window.isRereshing = true

                console.log('start token refresh')
                tokenRefresh({refreshToken})
                    .then(result => {
                        if (result) {
                            sessionStorage.setItem('pvEnergyUserInfo', JSON.stringify(result)) // 重新获取的token有效时间
                        }

                        window.isRereshing = false
                    })
                    .catch(error => {
                        console.log(error, 'error')
                        window.isRereshing = false
                    })
            }
        }
    }
}

export default request
