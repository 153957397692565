import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'


import 'element-plus/dist/index.css'
import './styles/index.scss'
import '@/permission/index'
// import 'default-passive-events'
import * as echarts from 'echarts';
import { handleSetData } from '@/utils/index'
handleSetData()
import {
    ScatterChart,
    BarChart,
    LineChart,
} from 'echarts/charts';
import {
    TooltipComponent,
    TitleComponent,
    LegendComponent,
    DataZoomComponent,
    MarkPointComponent,
    MarkLineComponent
} from 'echarts/components';

// 注册必须的组件
echarts.use([
    BarChart,
    ScatterChart,
    LineChart,
    TooltipComponent,
    TitleComponent,
    LegendComponent,
    DataZoomComponent,
    MarkPointComponent,
    MarkLineComponent
]);
import DirectiveLoading from '@/utils/loading'

const app = createApp(App)
app.config.errorHandler = (err, vm, info) => {
    console.error('Async error:', err, vm, info)
}
app.config.globalProperties.$echarts = echarts;
app.directive('clickoutside', {
    mounted(el, binding) {
        function documentHandler(e) {
            if (el.contains(e.target)) {
                return false;
            }
            if (binding.value) {
                binding.value(e);
            }
        }

        el._vueClickOutside_ = documentHandler;
        document.addEventListener('click', documentHandler);
    },
    beforeUnmount(el) {
        document.removeEventListener('click', el._vueClickOutside_);
        delete el._vueClickOutside_;
    }
});
app.use(ElementPlus,{
    locale: zhCn
})
app.use(DirectiveLoading)
app.use(store).use(router)
app.mount('#app')
