const index = {
  state: {
    token: '',
    expireAt: '',
    companyId: '',
    refreshToken: '',
    refreshExpireAt: '',
    userId: '',
    userInfo:{},
    projectId: ''

  },
  mutations: {
    setToken: (state, token) => {
      state.token = token
    },
    setProjectId: (state, companyId) => {
      state.projectId = projectId
    },
    setUserId: (state, userId) => {
      state.userId = userId
    },
    setUserAllInfo: (state, userInfo) => {
      state.userInfo = userInfo
    },
    

  },
  actions: {
    
  }
}

export default index
