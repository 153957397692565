import { createStore } from 'vuex';
import index from './modules/index'
import getters from './getters'
export default createStore({
  modules:{
    index,
  },
  getters
});

