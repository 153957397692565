<template>
  <router-view />
  <div
      class="user-examine-box"
      v-if="refreshSystem"
  >
    <div
        class="user-examine-content-box el-message-box"
    >
      <div class="el-message-box__header">
        <div class="el-message-box__title">提示</div>
      </div>
      <div class="el-message-box__content">
        <div class="el-message-box__container">
          <div>
            <div class="el-message-box__status">
              <el-icon><InfoFilled /></el-icon>
            </div>
            <div class="el-message-box__message">
              当前系统已更新，请手动刷新页面以免影响使用！
            </div>
          </div>
        </div>
      </div>

      <div class="el-message-box__btns">
        <el-button class="my-confirm-confirm" @click="handleExamineUserInfo"
        >确定</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import autofit from 'autofit.js'
import updateVersionMixins from '@/mixins/updateVersion.js'
import { InfoFilled } from '@element-plus/icons-vue'
export default {
  components: {InfoFilled},
  mixins: [updateVersionMixins],
  mounted () {
    if (sessionStorage.pvEnergyUserInfo) {
      let userInfo = JSON.parse(sessionStorage.pvEnergyUserInfo)
      this.$store.commit('setUserId', userInfo.userId)
      this.$store.commit('setToken', userInfo.token)
    }
    autofit.init({
      ignore: ['#map'],
      dh: 1080,
      dw: 1920,
      el: '.page-box',
      resize: true
    })

  }
}
</script>
<style lang="scss">
#app {
  width: 100%;
  height: 100%;
}
.user-examine-box {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.7);
  width: 100%;
  height: 100%;
  z-index: 999;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  &.user-examine-box {
    position: absolute;
  }
  .user-examine-content-box {
    height: fit-content;
    font-size: 18px;
    &.el-message-box {
      width: 535px;
      padding-bottom: 20px;
      border-radius: 12px;
      border: none;
      font-size: 18px;
      max-width: inherit;

      .el-message-box__header {
        position: relative;
        padding: 15px;
        padding-bottom: 10px;
        margin-bottom: 20px;
      }

      .el-message-box__title {
        font-size: 24px;
      }

      .el-message-box__status {
        font-size: 24px !important;
        .el-icon {
          color: #919191;
          width: 24px;
          height: 24px;
        }
      }

      .el-message-box__message {
        padding-left: 36px;
        padding-right: 12px;
        font-size: 20px;

        p {
          line-height: 24px;
        }
      }

      .el-message-box__container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .el-message-box__content {
        padding: 10px 15px;
        color: #000000;
        margin-bottom: 20px;
      }

      .el-message-box__btns {
        padding: 5px 15px 0;
        text-align: right;
        display: flex;
        justify-content: flex-end;

        .el-button {


          &.my-confirm-confirm {
            background-color: #22d9b0;
            border-radius: 20px;
            height: 40px;
            width: 120px;
            font-size: 22px;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
          }
        }
      }

    }
  }
}
</style>
